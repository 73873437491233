
<header class="header">
    <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
            <a id="mobile_btn" href="javascript:void(0);">
                <span class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <a [routerLink]="'/'" class="navbar-brand logo">
                <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
            </a>
        </div>
        <div class="main-menu-wrapper">
            <div class="menu-header">
                <a [routerLink]="'/'" class="menu-logo">
                    <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
                </a>
                <a id="menu_close" class="menu-close" href="javascript:void(0);">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <ul class="main-nav">
                <li [class.active]="base === '' || base === 'index'">
                    <a  [routerLink]="'/'" >Home</a>
                </li>
                <li  [class.active]="base === 'categories'">
                    <a [routerLink]="'/categories'">Categories</a>
                </li>
                <li [class.active]="base === 'providers'" class="has-submenu">
                    <a href="javascript:void(0)">Providers<i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li><a [routerLink]="'/providers/dashboard'">Dashboard</a></li>
                        <li><a [routerLink]="'/providers/services'">Services</a></li>
                        <li><a [routerLink]="'/providers/booking-list'">Bookings</a></li>
                        <li><a [routerLink]="'/providers/profile-settings'">Profile Settings</a></li>
                        <li><a [routerLink]="'/providers/wallet'">Wallet</a></li>
                        <li><a [routerLink]="'/providers/subscription'">Subscription</a></li>
                        <li><a [routerLink]="'/providers/availability'">Availability</a></li>
                        <li><a [routerLink]="'/providers/reviews'">Reviews</a></li>                           
                        <li><a [routerLink]="'providers/payment'">Payment</a></li>
                    </ul>
                </li>
                <li [class.active]="base === 'customers'" class="has-submenu">
                    <a href="javascript:void(0)">Customers <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu" id="submenu">
                        <li><a [routerLink]="'/customers/dashboard'" >Dashboard</a></li>
                        <li><a [routerLink]="'/customers/my-bookings'">Bookings</a></li>   
                        <li><a [routerLink]="'/customers/profile-settings'">Profile Settings</a></li>                     
                        <li><a [routerLink]="'/customers/wallet'">Wallet</a></li>
                        <li><a [routerLink]="'/customers/reviews'">Reviews</a></li>  
                        <li><a [routerLink]="'/customers/payment'">Payment</a></li>  
                    </ul>
                </li>
                <li [class.active]="base === 'pages'" class="has-submenu">
                    <a href="javascript:void(0)">Pages <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                        <li><a [routerLink]="'/pages/search'">Search</a></li>
                        <li><a [routerLink]="'/pages/service-details'">Service Details</a></li>
                        <li><a [routerLink]="'/pages/add-service'">Add Service</a></li>                       
                        <li><a [routerLink]="'/pages/edit-service'">Edit Service</a></li>
                        <li><a [routerLink]="'/pages/chat'">Chat</a></li>
                        <li><a [routerLink]="'/pages/notifications'">Notifications</a></li>
                        <li><a [routerLink]="'/pages/about-us'">About Us</a></li>
                        <li><a [routerLink]="'/pages/contact-us'">Contact Us</a></li>
                        <li><a [routerLink]="'/pages/faq'">Faq</a></li>
                        <li><a [routerLink]="'/pages/terms-conditions'">Terms & Conditions</a></li>
						<li><a [routerLink]="'/pages/privacy-policy'">Privacy Policy</a></li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="'/admin'">Admin</a>
                </li>
                <li *ngIf="base === '' || base === 'index' || base === 'categories' || base === 'pages'">
                    <a href="javascript:void(0);" data-toggle="modal" data-target="#provider-register">Become a Professional</a>
                </li>
                <li *ngIf="base === '' || base === 'index' || base === 'categories' || base === 'pages'">
                    <a href="javascript:void(0);" data-toggle="modal" data-target="#user-register">Become a User</a>
                </li>
            </ul>
        </div>
        <ul *ngIf="base === '' || base === 'index' || base === 'categories' || base === 'pages'" class="nav header-navbar-rht">
            <li class="nav-item">
                <a class="nav-link header-login" href="javascript:void(0);" data-toggle="modal" data-target="#login_modal">Login</a>
            </li>
        </ul>
        <ul class="nav header-navbar-rht" *ngIf="base === 'customers'">
            <li class="nav-item desc-list wallet-menu">
                <a [routerLink]="'/customers/wallet'" class="nav-link header-login">
                    <img src="assets/img/wallet.png" alt="" class="mr-2 wallet-img"><span>Wallet:</span> $1875
                </a>
            </li>

            <!-- Notifications -->
            <li class="nav-item dropdown logged-item">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-bell"></i> <span class="badge badge-pill bg-yellow">2</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right notifications">
                    <div class="topnav-dropdown-header">
                        <span class="notification-title">Notifications</span>
                        <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                    </div>
                    <div class="noti-content">
                        <ul class="notification-list">
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/provider/provider-01.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Thomas Herzberg  has rejected the service</span></p>
                                            <p class="noti-time"><span class="notification-time">Today 01:00 AM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/provider/provider-02.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Matthew Garcia have completed the service</span></p>
                                            <p class="noti-time"><span class="notification-time">Today 11:07 AM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/provider/provider-03.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Yolanda Potter have inprogress the service</span></p>
                                            <p class="noti-time"><span class="notification-time">17 Sep 2020 9:00 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/provider/provider-04.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Ricardo Flemings have completed the service</span></p>
                                            <p class="noti-time"><span class="notification-time">16 Sep 2020 12:00 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>

                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/provider/provider-05.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Maritza Wasson have inprogress the service</span></p>
                                            <p class="noti-time"><span class="notification-time">15 Sep 2020 5:32 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <a [routerLink]="'/pages/notifications'">View all Notifications</a>
                    </div>
                </div>
            </li>
            <!-- /Notifications -->

            <!-- chat -->
            <li class="nav-item logged-item">
                <a [routerLink]="'/pages/chat'" class="nav-link">
                    <i class="fa fa-comments" aria-hidden="true"></i>
                </a>
            </li>
            <!-- /chat -->
            
            <li class="nav-item dropdown has-arrow logged-item">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
                    <span class="user-img">
                        <img class="rounded-circle" src="assets/img/customer/user-01.jpg" alt="">
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <div class="user-header">
                        <div class="avatar avatar-sm">
                            <img class="avatar-img rounded-circle" src="assets/img/customer/user-01.jpg" alt="">
                        </div>
                        <div class="user-text">
                            <h6>Jeffrey Akridge</h6>
                            <p class="text-muted mb-0">User</p>
                        </div>
                    </div>
                    <a class="dropdown-item" [routerLink]="'/customers/dashboard'">Dashboard</a>
                    <a class="dropdown-item" [routerLink]="'/customers/my-bookings'">Bookings</a>
                    <a class="dropdown-item" [routerLink]="'/customers/profile-settings'">Profile Settings</a>
                    <a class="dropdown-item" [routerLink]="'/customers/wallet'">Wallet</a>
                    <a class="dropdown-item" [routerLink]="'/customers/reviews'">Reviews</a>
                    <a class="dropdown-item" [routerLink]="''">Logout</a>
                </div>
            </li>
        </ul>
        <ul  *ngIf="base === 'providers'" class="nav header-navbar-rht">

            <li class="nav-item desc-list">
                <a [routerLink]="'/pages/add-service'" class="nav-link header-login">
                    <i class="fas fa-plus-circle mr-1"></i> <span>Post a Service</span>
                </a>
            </li>

            <!-- Notifications -->
            <li class="nav-item dropdown logged-item">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown">
                    <i class="fas fa-bell"></i> <span class="badge badge-pill bg-yellow">1</span>
                </a>
                <div class="dropdown-menu notify-blk dropdown-menu-right notifications">
                    <div class="topnav-dropdown-header">
                        <span class="notification-title">Notifications</span>
                        <a href="javascript:void(0)" class="clear-noti">Clear All  </a>
                    </div>
                    <div class="noti-content">
                        <ul class="notification-list">
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/customer/user-01.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Jeffrey Akridge has booked your service</span></p>
                                            <p class="noti-time"><span class="notification-time">Today 10:04 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/customer/user-02.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Nancy Olson has booked your service</span></p>
                                            <p class="noti-time"><span class="notification-time">Today 9:45 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/customer/user-03.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Ramona Kingsley has booked your service</span></p>
                                            <p class="noti-time"><span class="notification-time">Yesterday 8:17 AM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/customer/user-04.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Ricardo Lung has booked your service</span></p>
                                            <p class="noti-time"><span class="notification-time">Yesterday 6:20 AM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a [routerLink]="'/pages/notifications'">
                                    <div class="media">
                                        <span class="avatar avatar-sm">
                                            <img class="avatar-img rounded-circle" alt="User Image" src="assets/img/customer/user-05.jpg">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"> <span class="noti-title">Annette Silva has booked your service</span></p>
                                            <p class="noti-time"><span class="notification-time">17 Sep 2020 10:04 PM</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <a [routerLink]="'/pages/notifications'">View all Notifications</a>
                    </div>
                </div>
            </li>
            <!-- /Notifications -->

            <!-- chat -->
            <li class="nav-item logged-item">
                <a [routerLink]="'/pages/chat'" class="nav-link">
                    <i class="fa fa-comments" aria-hidden="true"></i>
                </a>
            </li>
            <!-- /chat -->
            
            <!-- User Menu -->
            <li class="nav-item dropdown has-arrow logged-item">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
                    <span class="user-img">
                        <img class="rounded-circle" src="assets/img/provider/provider-01.jpg" alt="" width="31">
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <div class="user-header">
                        <div class="avatar avatar-sm">
                            <img class="avatar-img rounded-circle" src="assets/img/provider/provider-01.jpg" alt="">
                        </div>
                        <div class="user-text">
                            <h6 class="text-truncate">Thomas</h6>
                            <p class="text-muted mb-0">Provider</p>
                        </div>
                    </div>
                    <a class="dropdown-item" [routerLink]="'/providers/dashboard'">Dashboard</a>
                    <a class="dropdown-item" [routerLink]="'/providers/services'">My Services</a>
                    <a class="dropdown-item" [routerLink]="'/providers/booking-list'">Booking List</a>
                    <a class="dropdown-item" [routerLink]="'/providers/profile-settings'">Profile Settings</a>
                    <a class="dropdown-item" [routerLink]="'/providers/wallet'">Wallet</a>
                    <a class="dropdown-item" [routerLink]="'/providers/subscription'">Subscription</a>
                    <a class="dropdown-item" [routerLink]="'/providers/availability'">Availability</a>
                    <a class="dropdown-item" [routerLink]="'/pages/chat'">Chat</a>
                    <a class="dropdown-item" [routerLink]="''">Logout</a>
                </div>
            </li>
            <!-- /User Menu -->

        </ul>
    </nav>
</header>
