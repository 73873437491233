import { Injectable } from '@angular/core';

import { InMemoryDbService } from 'angular-in-memory-web-api';
import { duration } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DataService implements InMemoryDbService {
  constructor() {}

  createDb() {
    let services = [
      {
        id: 1,
        service_img: 'assets/img/services/service-01.jpg',
        user_img: 'assets/img/customer/user-01.jpg',
        service_name: 'Toughened Glass Fitting Services',
        user_name: 'Jeffrey Akridge',
        name: 'Cleaning',
        country: 'Wayne, New Jersey ',
        amount: '$25',
        mobile: 'xxxxxxxx49',
        date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'User Rejected',
      },
      {
        id: 2,
        service_img: 'assets/img/services/service-02.jpg',
        user_img: 'assets/img/customer/user-02.jpg',
        service_name: 'Car Repair Services',
        user_name: 'Nancy Olson',
        name: 'Automobile',
        country: 'Hanover, Maryland ',
        amount: '$50',
        mobile: 'xxxxxxxx85',
        date: 'Wed Jul 21 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Payment Completed',
      },
      {
        id: 3,
        service_img: 'assets/img/services/service-03.jpg',
        user_img: 'assets/img/customer/user-03.jpg',
        service_name: 'Electric Panel Repairing Service',
        user_name: 'Ramona Kingsley',
        name: 'Electrical',
        country: 'Kalispell, Montana ',
        amount: '$45',
        mobile: 'xxxxxxxx30',
        date: 'Wed Jul 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Provider Rejected',
      },
      {
        id: 4,
        service_img: 'assets/img/services/service-04.jpg',
        user_img: 'assets/img/customer/user-04.jpg',
        service_name: 'Steam Car Wash',
        user_name: 'Ricardo Lung',
        name: 'Car Wash',
        country: 'Electra, Texas',
        amount: '$14',
        mobile: 'xxxxxxxx74',
        date: 'Wed Jul 14 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Payment Completed',
      },
      {
        id: 5,
        service_img: 'assets/img/services/service-05.jpg',
        user_img: 'assets/img/customer/user-05.jpg',
        service_name: 'House Cleaning Services',
        user_name: 'Annette Silva',
        name: 'Cleaning',
        country: 'Sylvester, Georgia',
        amount: '$100',
        mobile: 'xxxxxxxx91',
        date: 'Wed Jul 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Payment Completed',
      },
      {
        id: 6,
        service_img: 'assets/img/services/service-06.jpg',
        user_img: 'assets/img/customer/user-06.jpg',
        service_name: 'Computer & Server AMC Service',
        user_name: 'Stephen Wilson',
        name: 'Computer',
        country: 'Los Angeles, California',
        amount: '$80',
        mobile: 'xxxxxxxx92',
        date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Provider Rejected',
      },
      {
        id: 7,
        service_img: 'assets/img/services/service-07.jpg',
        user_img: 'assets/img/customer/user-07.jpg',
        service_name: 'Interior Designing',
        user_name: ' Ryan Rodriguez',
        name: 'Interior',
        country: 'Hanover, Maryland',
        amount: '$5',
        mobile: 'xxxxxxxx28',
        date: 'Wed Jul 24 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Provider Rejected',
      },
      {
        id: 8,
        service_img: 'assets/img/services/service-08.jpg',
        user_img: 'assets/img/customer/user-08.jpg',
        service_name: 'Building Construction Services',
        user_name: 'Lucile Devera',
        name: 'Construction',
        country: 'Burr Ridge, Illinois',
        amount: '$75',
        mobile: 'xxxxxxxx62',
        date: 'Wed Jul 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'Provider Rejected',
      },
      {
        id: 9,
        service_img: 'assets/img/services/service-09.jpg',
        user_img: 'assets/img/customer/user-09.jpg',
        service_name: 'Commercial Painting Services',
        user_name: ' Jeffrey Akridge',
        name: 'Painting',
        country: 'Huntsville, Alabama',
        amount: '$500',
        mobile: 'xxxxxxxx75',
        date: 'Wed Jul 25 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'User Rejected',
      },
      {
        id: 10,
        service_img: 'assets/img/services/service-10.jpg',
        user_img: 'assets/img/customer/user-10.jpg',
        service_name: 'Plumbing Services',
        user_name: ' Jeffrey Akridge',
        name: 'Plumbing',
        country: 'Richmond, Virginia',
        amount: '$150',
        mobile: 'xxxxxxxx13',
        date: 'Wed Jul 03 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'User Rejected',
      },
    ];

    let specialityList = [
      {
        id: 1,
        key: '1',
        speciality: 'Computer',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-01.jpg',
        ratings: '21',
        checked: true,
      },
      {
        id: 2,
        key: '2',
        speciality: 'Interior',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-02.jpg',
        ratings: '14',
        checked: true,
      },
      {
        id: 3,
        key: '3',
        speciality: 'Car Wash',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-03.jpg',
        ratings: '15',
        checked: false,
      },
      {
        id: 4,
        key: '4',
        speciality: 'Cleaning',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-04.jpg',
        ratings: '13',
        checked: false,
      },
      {
        id: 5,
        key: '5',
        speciality: 'Electrical',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-05.jpg',
        ratings: '10',
        checked: true,
      },
      {
        id: 5,
        key: '6',
        speciality: 'Construction',
        date: 'Wed Mar 07 2020 10:04:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/category/category-06.jpg',
        ratings: '8',
        checked: true,
      },
    ];

    let appointments = [
      {
        id: 1,
        service_name: 'Car Repair Services',
        img: 'assets/img/services/service-02.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        user_id: '1',
        booking_date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        booking_time: '13:00:00 - 14:00:00',
        phone: '410-242-3850',
        location: '223 Jehovah Drive Roanoke',
        status: 'Complete request sent to user',
        amount: '$200.00',
        option1: '',
        option2: '',
        option3: '',
      },
      {
        id: 2,
        service_name: 'Electric Panel Repairing Service',
        img: 'assets/img/services/service-03.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        user_id: '2',
        booking_date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        booking_time: '13:00:00 - 14:00:00',
        phone: '410-242-3850',
        location: '223 Jehovah Drive Roanoke',
        status: 'Inprogress',
        amount: '$300.00',
        option1: 'Chat',
        option2: 'User request accept',
        option3: 'Cancel the service',
      },
      {
        id: 3,
        service_name: 'Steam Car Wash',
        img: 'assets/img/services/service-04.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        user_id: '3',
        booking_date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        booking_time: '13:00:00 - 14:00:00',
        phone: '410-242-3850',
        location: '223 Jehovah Drive Roanoke',
        status: 'Rejected by user',
        amount: '$150.00',
        option1: '',
        option2: 'Reason',
        option3: '',
      },
      {
        id: 4,
        service_name: 'House Cleaning Services',
        user_id: '4',
        img: 'assets/img/services/service-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-01.jpg',
        booking_date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        booking_time: '13:00:00 - 14:00:00',
        phone: '410-242-3850',
        location: '223 Jehovah Drive Roanoke',
        status: 'Pending',
        amount: '$150.00',
        option1: '',
        option2: 'User request accept',
        option3: 'Cancel the service',
      },
      {
        id: 5,
        service_name: 'Interior Designing',
        user_id: '5',
        img: 'assets/img/services/service-05.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        booking_date: 'Wed Jul 23 2020 09:41:48 GMT+0530 (India Standard Time)',
        booking_time: '13:00:00 - 14:00:00',
        phone: '410-242-3850',
        location: '223 Jehovah Drive Roanoke',
        status: 'Pending',
        amount: '$200.00',
        option1: '',
        option2: 'User request accept',
        option3: 'Cancel the service',
      },
    ];

    let reviews = [
      {
        id: 1,
        service_img: 'assets/img/services/service-08.jpg',
        user_img: 'assets/img/customer/user-01.jpg',
        user_name: 'Jeffrey Akridge',
        ratings: '5',
        description: 'Building Construction Services',
        date: 'Wed Jul 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Good Work',
      },
      {
        id: 2,
        service_img: 'assets/img/services/service-09.jpg',
        user_img: 'assets/img/customer/user-02.jpg',
        user_name: 'Nancy Olson',
        ratings: '5',
        description: 'Commercial Painting Services',
        date: 'Wed Jul 05 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Best Work',
      },
      {
        id: 3,
        service_img: 'assets/img/services/service-10.jpg',
        user_img: 'assets/img/customer/user-03.jpg',
        user_name: 'Ramona Kingsley',
        ratings: '4',
        description: 'Plumbing Services',
        date: 'Wed Jul 29 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Excellent Service',
      },
      {
        id: 4,
        service_img: 'assets/img/services/service-11.jpg',
        user_img: 'assets/img/customer/user-04.jpg',
        user_name: 'Jeffrey Akridge',
        ratings: '5',
        description: 'Wooden Carpentry Work',
        date: 'Wed Jul 26 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Thanks',
      },
      {
        id: 5,
        service_img: 'assets/img/services/service-12.jpg',
        user_img: 'assets/img/customer/user-05.jpg',
        user_name: 'Annette Silva',
        ratings: '5',
        description: 'Air Conditioner Service',
        date: 'Wed Jul 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Good Work',
      },
      {
        id: 6,
        service_img: 'assets/img/services/service-01.jpg',
        user_img: 'assets/img/customer/user-06.jpg',
        user_name: 'Stephen Wilson',
        ratings: '5',
        description: 'Toughened Glass Fitting Services',
        date: 'Wed Jul 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Great',
      },
      {
        id: 7,
        service_img: 'assets/img/services/service-02.jpg',
        user_img: 'assets/img/customer/user-07.jpg',
        user_name: 'Ryan Rodriguez',
        ratings: '5',
        description: 'Car Repair Services',
        date: 'Wed Jul 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Good Support',
      },
      {
        id: 8,
        service_img: 'assets/img/services/service-03.jpg',
        user_img: 'assets/img/customer/user-08.jpg',
        user_name: 'Jeffrey Akridge',
        ratings: '5',
        description: 'Electric Panel Repairing Service',
        date: 'Wed Jul 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        commands: 'Good Work',
      },
    ];

    let comments = [
      {
        name: 'Michelle Fairfax',
        email: 'Fairfax@example.com',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: 'Elsie Gilley',
        email: 'Fairfax@example.com',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
    ];

    let plans = [
      {
        id: 1,
        name: 'Basic',
        price: '0.00',
        duration: '3',
        expiry: '90',
        start_date: '03-04-2020',
        end_date: '02-07-2020',
        status: 'paid',
      },
      {
        id: 2,
        name: 'Standard',
        price: '50.00',
        duration: '6',
        expiry: '180',
        start_date: '03-04-2020',
        end_date: '02-07-2020',
        status: 'paid',
      },
      {
        id: 3,
        name: 'Enterprice',
        price: '1200.00',
        duration: '12',
        expiry: '360',
        start_date: '03-04-2020',
        end_date: '02-07-2020',
        status: 'paid',
      },
    ];

    let available_days = [
      {
        id: 1,
        day: 'All Days',
      },
      {
        id: 2,
        day: 'Monday',
      },
      {
        id: 3,
        day: 'Tuesday',
      },
      {
        id: 4,
        day: 'Wednesday',
      },
      {
        id: 5,
        day: 'Thursday',
      },
      {
        id: 6,
        day: 'Friday',
      },
      {
        id: 7,
        day: 'Saturday',
      },
      {
        id: 8,
        day: 'Sunday',
      },
    ];

    let transactions = [
      {
        id: 1,
        user_id: '1',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3486',
        credit: '399',
        tax_amount: '0',
        debit: '0',
        available: '3885',
        reason: 'Complete the Service',
        status: 'Credit',
      },
      {
        id: 2,
        user_id: '2',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3386',
        credit: '100',
        tax_amount: '0',
        debit: '0',
        available: '3486',
        reason: 'Complete the Service',
        status: 'Credit',
      },
      {
        id: 3,
        user_id: '3',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3236',
        credit: '150',
        tax_amount: '0',
        debit: '100',
        available: '3286',
        reason: 'Withdraw',
        status: 'Debit',
      },
      {
        id: 4,
        user_id: '4',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3211',
        credit: '25',
        tax_amount: '0',
        debit: '0',
        available: '3236',
        reason: 'Complete the Service',
        status: 'Credit',
      },
      {
        id: 5,
        user_id: '5',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3186',
        credit: '25',
        tax_amount: '0',
        debit: '100',
        available: '3111',
        reason: 'Withdraw',
        status: 'Debit',
      },
      {
        id: 6,
        user_id: '6',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3236',
        credit: '0',
        tax_amount: '0',
        debit: '50',
        available: '3186',
        reason: 'Withdraw',
        status: 'Debit',
      },
      {
        id: 7,
        user_id: '7',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3136',
        credit: '100',
        tax_amount: '0',
        debit: '100',
        available: '3136',
        reason: 'Withdraw',
        status: 'Debit',
      },
      {
        id: 8,
        user_id: '8',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3036',
        credit: '100',
        tax_amount: '0',
        debit: '0',
        available: '3136',
        reason: 'Complete the Service',
        status: 'Credit',
      },
      {
        id: 9,
        user_id: '9',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3136',
        credit: '4',
        tax_amount: '0',
        debit: '0',
        available: '3036',
        reason: 'Complete the Service',
        status: 'Credit',
      },
      {
        id: 10,
        user_id: '10',
        date: 'Wed Jul 18 2020 09:41:48 GMT+0530 (India Standard Time)',
        wallet: '3028',
        credit: '4',
        tax_amount: '0',
        debit: '0',
        available: '3032',
        reason: 'Complete the Service',
        status: 'Credit',
      },
    ];

    let notifications = [
      {
        id: 1,
        text: 'Jeffrey Akridge has booked your service',
        date: 'Wed Oct 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-01.jpg',
      },
      {
        id: 2,
        text: 'Nancy Olson booked your service',
        date: 'Wed Oct 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
      },
      {
        id: 3,
        text: 'Ramona Kingsley booked your service',
        date: 'Wed Oct 9 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-03.jpg',
      },
      {
        id: 4,
        text: 'Ricardo Lung have rejected the service',
        date: 'Wed Oct 8 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-04.jpg',
      },
      {
        id: 5,
        text: 'Annette Silva has booked your service',
        date: 'Wed Oct 7 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-05.jpg',
      },
      {
        id: 6,
        text: 'Stephen Wilson has booked your service',
        date: 'Wed Oct 6 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-06.jpg',
      },
      {
        id: 7,
        text: 'Ryan Rodriguez has booked your service',
        date: 'Wed Oct 5 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-07.jpg',
      },
      {
        id: 8,
        text: 'Lucile Devera booked your service',
        date: 'Wed oct 4 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-08.jpg',
      },
      {
        id: 9,
        text: 'Roland Storey has booked your service',
        date: 'Wed Oct 3 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-09.jpg',
      },
      {
        id: 10,
        text: 'Lindsey Parmley has cancelled the service',
        date: 'Wed oct 2 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-10.jpg',
      },
    ];

    let categories = [
      {
        id: 1,
        name: 'Computer',
        img: 'assets/img/category/category-01.jpg',
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        name: 'Interior',
        img: 'assets/img/category/category-02.jpg',
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        name: 'Car Wash',
        img: 'assets/img/category/category-03.jpg',
        date: 'Wed Sep 9 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 4,
        name: 'Cleaning',
        img: 'assets/img/category/category-04.jpg',
        date: 'Wed Sep 8 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 5,
        name: 'Electrical',
        img: 'assets/img/category/category-05.jpg',
        date: 'Wed Sep 7 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 6,
        name: 'Construction',
        img: 'assets/img/category/category-06.jpg',
        date: 'Wed Sep 6 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 7,
        name: 'Plumbing',
        img: 'assets/img/category/category-07.jpg',
        date: 'Wed Sep 5 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 8,
        name: 'Carpentry',
        img: 'assets/img/category/category-08.jpg',
        date: 'Wed Sep 4 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 9,
        name: 'Appliance',
        img: 'assets/img/category/category-09.jpg',
        date: 'Wed Sep 3 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 10,
        name: 'Auto Mobile',
        img: 'assets/img/category/category-09.jpg',
        date: 'Wed Sep 3 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 11,
        name: 'Painting',
        img: 'assets/img/category/category-09.jpg',
        date: 'Wed Sep 3 2020 09:41:48 GMT+0530 (India Standard Time)',
      }
    ];

    let sub_categories = [
      {
        id: 1,
        name: 'Bus Service',
        cat_id: 7,
        img: 'assets/admin/img/sub-category/sub-category-01.jpg',
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        name: 'Office Construction',
        cat_id: 6,
        img: 'assets/admin/img/sub-category/sub-category-02.jpg',
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        name: 'Indoor Interior',
        cat_id: 2,
        img: 'assets/admin/img/sub-category/sub-category-03.jpg',
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 4,
        name: 'House Cleaning',
        cat_id: 4,
        img: 'assets/admin/img/sub-category/sub-category-04.jpg',
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 5,
        name: 'Office Electrical',
        cat_id: 5,
        img: 'assets/admin/img/sub-category/sub-category-05.jpg',
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 6,
        name: 'Home Carpentry',
        cat_id: 8,
        img: 'assets/admin/img/sub-category/sub-category-06.jpg',
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 7,
        name: 'Hardware',
        cat_id: 1,
        img: 'assets/admin/img/sub-category/sub-category-07.jpg',
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 8,
        name: 'Software',
        cat_id: 1,
        img: 'assets/admin/img/sub-category/sub-category-08.jpg',
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 9,
        name: 'Wall Painting',
        cat_id: 11,
        img: 'assets/admin/img/sub-category/sub-category-09.jpg',
        date: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 10,
        name: 'Body Wash',
        cat_id: 3,
        img: 'assets/admin/img/sub-category/sub-category-10.jpg',
        date: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',
      }
    ];

    let all_reports = [
      {
        id: 1,
        date: 'Wed Sep 16 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$25',
        status: 'Pending',
        updated: 'Today 01:38 PM'
      },
      {
        id: 2,
        date: 'Wed Sep 15 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$50',
        status: 'Complete Request',
        updated: 'Yesterday 01:35 PM'
      },
      {
        id: 3,
        date: 'Wed Sep 14 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ramona Kingsley',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$45',
        status: 'Inprogress',
        updated: 'Yesterday 01:31 PM'
      },      
      {
        id: 4,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: 'Steam Car Wash',
        amount: '$14',
        status: 'Pending',
        updated: 'Yesterday 01:29 PM'
      },
      {
        id: 5,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silva',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Rejected by User',
        updated: 'Yesterday 01:21 PM'
      },
      {
        id: 6,
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$25',
        status: 'Pending',
        updated: 'Yesterday 01:03 PM'
      },
      {
        id: 7,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: '	Interior Designing',
        amount: '$5',
        status: 'Pending',
        updated: '10 Sep 2020 08:03 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$75',
        status: 'Complete Request',
        updated: '9 Sep 2020 09:38 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$75',
        status: 'Inprogress',
        updated: '8 Sep 2020 09:37 PM'
      },
      {
        id: 9,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Commercial Painting Services',
        amount: '$500',
        status: 'Pending',
        updated: '7 Sep 2020 06:27 PM'
      },
      { 
        id: 10,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$75',
        status: 'Cancelled by Provider',
        updated: '6 Sep 2020 04:27 PM'
      },
    ];

    let pending_reports = [
      {
        id: 1,
        date: 'Wed Sep 15 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$75',
        status: 'Pending',
        updated: 'Yesterday 01:35 PM'
      },
      {
        id: 2,
        date: 'Wed Sep 14 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ramona Kingsley',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$45',
        status: 'Pending',
        updated: 'Yesterday 01:31 PM'
      },
      {
        id: 3,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: '	Steam Car Wash',
        amount: '$75',
        status: 'Pending',
        updated: 'Yesterday 01:29 PM'
      },
      {
        id: 4,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silva',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Pending',
        updated: 'Yesterday 01:21 PM'
      },
      {
        id: 5,
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$100',
        status: 'Pending',
        updated: 'Yesterday 01:03 PM'
      },
      {
        id: 6,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: 'Interior Designing',
        amount: '$10',
        status: 'Pending',
        updated: '10 Sep 2020 08:03 PM'
      },
      {
        id: 7,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Building Construction Services',
        amount: '$100',
        status: 'Pending',
        updated: '9 Sep 2020 09:38 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Commercial Painting Services',
        amount: '$500',
        status: 'Pending',
        updated: '8 Sep 2020 09:37 PM'
      },
      {
        id: 9,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$100',
        status: 'Pending',
        updated: '7 Sep 2020 06:27 PM'
      },
      {
        id: 10,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$100',
        status: 'Pending',
        updated: 'Today 01:38 PM'
      }
    ];

    let inprogress_reports = [
      {
        id: 1,
        date: 'Wed Sep 14 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$45',
        status: 'Inprogress',
        updated: 'Yesterday 01:31 PM'
      },
      {
        id: 2,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: 'Steam Car Wash',
        amount: '$14',
        status: 'Inprogress',
        updated: 'Yesterday 01:29 PM'
      },
      {
        id: 3,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silva',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Inprogress',
        updated: 'Yesterday 01:21 PM'
      },
      {
        id: 4,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$80',
        status: 'Inprogress',
        updated: 'Yesterday 01:03 PM'
      },
      {
        id: 5,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: 'Interior Designing',
        amount: '$50',
        status: 'Inprogress',
        updated: '10 Sep 2020 08:03 PM'
      },
      {
        id: 6,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Building Construction Services',
        amount: '$75',
        status: 'Inprogress',
        updated: '09 Sep 2020 09:38 PM'
      },
      {
        id: 7,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Commercial Painting Services',
        amount: '$500',
        status: 'Inprogress',
        updated: '8 Sep 2020 09:37 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$100',
        status: 'Inprogress',
        updated: '7 Sep 2020 06:27 PM'
      },
      {
        id: 9,
        date: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$100',
        status: 'Inprogress',
        updated: 'Today 01:38 PM'
      },
      {
        id: 10,
        date: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$100',
        status: 'Inprogress',
        updated: 'Yesterday 01:35 PM'
      }
    ];

    let completed_reports = [
      {
        id: 1,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: 'Steam Car Wash',
        amount: '$45',
        status: 'Completed',
        updated: 'Yesterday 01:29 PM',
        type_name: 'Normal',
        ratings: '4.0',
        commands: 'Good Work'
      },
      {
        id: 2,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silvaw',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Completed',
        updated: 'Yesterday 01:21 PM',
        type_name: 'Excellent',
        ratings: '5.0',
        commands: 'Best Work'
      },
      {
        id: 3,
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$80',
        status: 'Completed',
        updated: 'Yesterday 01:03 PM',
        type_name: 'Excellent',
        ratings: '5.0',
        commands: 'Excellent Service'
      },
      {
        id: 4,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: 'Interior Designing',
        amount: '$55',
        status: 'Completed',
        updated: '10 Sep 2020 08:03 PM',
        type_name: 'Excellent',
        ratings: '5.0',
        commands: 'Thanks'        
      },
      {
        id: 5,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Building Construction Services',
        amount: '$75',
        status: 'Completed',
        updated: '09 Sep 2020 09:38 PM',
        type_name: 'Excellent',
        ratings: '5.0',
        commands: 'Amazing' 
      },
      {
        id: 6,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Building Construction Services',
        amount: '$500',
        status: 'Completed',
        updated: '8 Sep 2020 09:37 PM',
        type_name: 'Normal',
        ratings: '4.0',
        commands: 'Great' 
      },
      {
        id: 7,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$150',
        status: 'Completed',
        updated: '7 Sep 2020 06:27 PM',
        type_name: 'Good',
        ratings: '4.0',
        commands: 'Good Support'
      },
      {
        id: 8,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$100',
        status: 'Completed',
        updated: 'Today 01:38 PM',
        type_name: 'Good',
        ratings: '4.0',
        commands: 'Good Work'
      },
      {
        id: 9,
        date: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$50',
        status: 'Completed',
        updated: 'Yesterday 01:35 PM',
        type_name: 'Normal',
        ratings: '4.0',
        commands: 'Great'
      },
      {
        id: 10,
        date: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ramona Kingsley',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$100',
        status: 'Completed',
        updated: 'Yesterday 01:31 PM',
        type_name: 'Normal',
        ratings: '4.0',
        commands: '-'
        
      }
    ];

    let rejected_reports = [      
      {
        id: 1,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silvaw',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Rejected',
        updated: 'Yesterday 01:21 PM'
      },
      {
        id: 2,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$80',
        status: 'Amount refund to Provider',
        updated: 'Yesterday 01:03 PM'
      },
      {
        id: 3,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: 'Interior Designing',
        amount: '$55',
        status: 'Amount refund to Provider',
        updated: '10 Sep 2020 08:03 PM'
      },
      {
        id: 4,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Building Construction Services',
        amount: '$75',
        status: 'Rejected',
        updated: '09 Sep 2020 09:38 PM'
      },
      {
        id: 5,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Building Construction Services',
        amount: '$500',
        status: 'Amount refund to Provider',
        updated: '8 Sep 2020 09:37 PM'
      },
      {
        id: 6,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$150',
        status: 'Amount refund to Provider',
        updated: '7 Sep 2020 06:27 PM'
      },
      {
        id: 7,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$100',
        status: 'Rejected',
        updated: 'Today 01:38 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$50',
        status: 'Amount refund to Provider',
        updated: 'Yesterday 01:35 PM'
      },
      {
        id: 9,
        date: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ramona Kingsley',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$100',
        status: 'Rejected',
        updated: 'Yesterday 01:31 PM'
      },
      {
        id: 10,
        date: 'Wed Sep 03 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: 'Steam Car Wash',
        amount: '$45',
        status: 'Amount refund to Provider',
        updated: 'Yesterday 01:29 PM'
      },
    ];

    let canceled_reports = [      
      
      {
        id: 1,
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Stephen Wilson',
        user_img: 'assets/img/customer/user-06.jpg',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        service: 'Computer & Server AMC Service',
        amount: '$80',
        status: 'Canceled',
        updated: 'Yesterday 01:03 PM'
      },
      {
        id: 2,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ryan Rodriguez',
        user_img: 'assets/img/customer/user-07.jpg',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        service: 'Interior Designing',
        amount: '$55',
        status: 'Canceled',
        updated: '10 Sep 2020 08:03 PM'
      },
      {
        id: 3,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lucile Devera',
        user_img: 'assets/img/customer/user-08.jpg',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        service: 'Building Construction Services',
        amount: '$75',
        status: 'Canceled',
        updated: '9 Sep 2020 09:38 PM'
      },
      {
        id: 4,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Roland Storey',
        user_img: 'assets/img/customer/user-09.jpg',
        provider_name: 'David Morrison',
        provider_img: 'assets/img/provider/provider-09.jpg',
        service: 'Building Construction Services',
        amount: '$500',
        status: 'Canceled',
        updated: '8 Sep 2020 09:37 PM'
      },
      {
        id: 5,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Lindsey Parmley',
        user_img: 'assets/img/customer/user-10.jpg',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-10.jpg',
        service: 'Plumbing Services',
        amount: '$150',
        status: 'Canceled',
        updated: '7 Sep 2020 06:27 PM'
      },
      {
        id: 6,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Jeffrey Akridge',
        user_img: 'assets/img/customer/user-01.jpg',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        service: 'Toughened Glass Fitting Services',
        amount: '$100',
        status: 'Canceled',
        updated: 'Today 01:38 PM'
      },
      {
        id: 7,
        date: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Nancy Olson',
        user_img: 'assets/img/customer/user-02.jpg',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        service: 'Car Repair Services',
        amount: '$50',
        status: 'Canceled',
        updated: 'Yesterday 01:35 PM'
      },
      {
        id: 8,
        date: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ramona Kingsley',
        user_img: 'assets/img/customer/user-03.jpg',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        service: 'Electric Panel Repairing Service',
        amount: '$100',
        status: 'Canceled',
        updated: 'Yesterday 01:31 PM'
      },
      {
        id: 9,
        date: 'Wed Sep 03 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Ricardo Lung',
        user_img: 'assets/img/customer/user-04.jpg',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        service: 'Steam Car Wash',
        amount: '$45',
        status: 'Amount refund to Provider',
        updated: 'Yesterday 01:29 PM'
      },
      {
        id: 10,
        date: 'Wed Sep 02 2020 09:41:48 GMT+0530 (India Standard Time)',
        user_name: 'Annette Silvaw',
        user_img: 'assets/img/customer/user-05.jpg',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        service: 'House Cleaning Services',
        amount: '$100',
        status: 'Canceled',
        updated: 'Yesterday 01:21 PM'
      },      
    ];

    let ratings = [
      {
        id: 1,
        commands: 'Good',
        status: 'Active'
      },
      {
        id: 2,
        commands: 'Very Good',
        status: 'Active'
      },
      {
        id: 3,
        commands: 'Poor',
        status: 'Active'
      },
      {
        id: 4,
        commands: 'Fair',
        status: 'Inactive'
      },
      {
        id: 5,
        commands: 'Excellent',
        status: 'Inactive'
      },
      
    ];

    let wallet_report = [
      {
        id: 1,
        date: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/provider/provider-01.jpg',
        name: 'Thomas Herzberg',
        mobile: '832-212-0082',
        amount: '3885',
        role: 'Provider'
      },
      {
        id: 2,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-01.jpg',
        name: 'Jeffrey Akridge',
        mobile: '850-847-0459',
        amount: '1047',
        role: 'User'
      },
      {
        id: 3,
        date: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/provider/provider-02.jpg',
        name: 'Matthew Garcia',
        mobile: '918-454-4561',
        amount: '500',
        role: 'Provider'
      },
      {
        id: 4,
        date: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/provider/provider-03.jpg',
        name: 'Yolanda Potter',
        mobile: '360-281-3619',
        amount: '183',
        role: 'Provider'
      },
      {
        id: 5,
        date: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
        name: 'Nancy Olson',
        mobile: '901-690-8272',
        amount: '960',
        role: 'User'
      },
      {
        id: 6,
        date: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-03.jpg',
        name: 'Ramona Kingsley',
        mobile: '608-712-2413',
        amount: '718',
        role: 'User'
      },
      {
        id: 7,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/provider/provider-04.jpg',
        name: 'Ricardo Flemings',
        mobile: '631-374-3243',
        amount: '699',
        role: 'Provider'
      },
      {
        id: 8,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-04.jpg',
        name: 'Ricardo Lung',
        mobile: '714-564-6443',
        amount: '4422',
        role: 'User'
      },
    ];

    let wallet_history = [
      {
        id: 1,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-01.jpg',
        name: 'Jeffrey Akridge',
        current_amt: '1061',
        credit_amt: '-',
        debit_amt: '14',
        fee_amt: '-',
        available_amt: '1047',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },      
      {
        id: 2,
        date: 'Wed Sep 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-01.jpg',
        name: 'Jeffrey Akridge',
        current_amt: '1111',
        credit_amt: '-',
        debit_amt: '50',
        fee_amt: '-',
        available_amt: '1061',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
      {
        id: 3,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
        name: 'Ramona Kingsley',
        current_amt: '1211',
        credit_amt: '-',
        debit_amt: '100',
        fee_amt: '-',
        available_amt: '1111',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
      {
        id: 4,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-04.jpg',
        name: 'Ricardo Lung',
        current_amt: '1461',
        credit_amt: '-',
        debit_amt: '250',
        fee_amt: '-',
        available_amt: '1211',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
      {
        id: 5,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
        name: 'Annette Silva',
        current_amt: '1711',
        credit_amt: '-',
        debit_amt: '250',
        fee_amt: '-',
        available_amt: '1461',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
      {
        id: 6,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
        name: 'Stephen Wilson',
        current_amt: '2211',
        credit_amt: '-',
        debit_amt: '500',
        fee_amt: '-',
        available_amt: '1711',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
      {
        id: 7,
        date: 'Wed Jul 22 2020 09:41:48 GMT+0530 (India Standard Time)',
        img: 'assets/img/customer/user-02.jpg',
        name: 'Ryan Rodriguez',
        current_amt: '2711',
        credit_amt: '-',
        debit_amt: '500',
        fee_amt: '-',
        available_amt: '2211',
        reason: 'Booked a Service',
        pay_type: 'Debit',
        role: 'User'
      },
    ];

    let subscriptions = [
      {
        id: 1,
        date: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Thomas Herzberg',
        provider_img: 'assets/img/provider/provider-01.jpg',
        subscription: 'Enterprice',
        email: 'thomasherzberg@example.com',
        mobile: '832-212-0082',
        status: 'Pending',
      },
      {
        id: 2,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Matthew Garcia',
        provider_img: 'assets/img/provider/provider-02.jpg',
        subscription: 'Enterprice',
        email: 'matthewgarcia@example.com',
        mobile: '918-454-4561',
        status: 'Standard',
      },
      {
        id: 3,
        date: 'Wed Sep 20 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Yolanda Potter',
        provider_img: 'assets/img/provider/provider-03.jpg',
        subscription: 'Basic',
        email: 'yolandapotter@example.com',
        mobile: '360-281-3619',
        status: 'Pending',
      },      
      {
        id: 4,
        date: 'Wed Sep 15 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Ricardo Flemings',
        provider_img: 'assets/img/provider/provider-04.jpg',
        subscription: 'Standard',
        email: 'ricardoflemings@example.com',
        mobile: '631-374-3243',
        status: 'Pending',
      },
      {
        id: 5,
        date: 'Wed Sep 01 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Maritza Wasson',
        provider_img: 'assets/img/provider/provider-05.jpg',
        subscription: 'Basic',
        email: 'maritzawasson@example.com',
        mobile: '979-844-9766',
        status: 'Pending',
      },
      {
        id: 6,
        date: 'Wed Jul 24 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Marya Ruiz',
        provider_img: 'assets/img/provider/provider-06.jpg',
        subscription: 'Enterprice',
        email: 'maryaruiz@example.com',
        mobile: '814-537-9709',
        status: 'Pending',
      },
      {
        id: 7,
        date: 'Wed Sep 21 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Richard Hughes',
        provider_img: 'assets/img/provider/provider-07.jpg',
        subscription: 'Standard',
        email: 'richardhughes@example.com',
        mobile: '937-846-6789',
        status: 'Pending',
      },
      {
        id: 8,
        date: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        subscription: 'Basic',
        email: 'ninawilson@example.com',
        mobile: '419-523-6835',
        status: 'Pending',
      },
      {
        id: 9,
        date: 'Wed Sep 30 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Nina Wilson',
        provider_img: 'assets/img/provider/provider-08.jpg',
        subscription: 'Enterprice',
        email: 'davidmorrison@example.com',
        mobile: '703-214-9351',
        status: 'Pending',
      },
      {
        id: 10,
        date: 'Wed Sep 28 2020 09:41:48 GMT+0530 (India Standard Time)',
        provider_name: 'Linda Brooks',
        provider_img: 'assets/img/provider/provider-09.jpg',
        subscription: 'Basic',
        email: 'lindabrooks@example.com',
        mobile: '512-243-2686',
        status: 'Pending',
      },      
    ];

    let users = [
      {
        id: 1,
        name: 'Jeffrey Akridge',
        img: 'assets/img/customer/user-01.jpg',
        email: 'jeffreyakridge@example.com',
        mobile: '850-847-0459',
        signup: 'Wed Sep 10 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 12 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 2,
        name: 'Nancy Olson',
        img: 'assets/img/customer/user-02.jpg',
        email: 'nancyolson@example.com',
        mobile: '901-690-8272',
        signup: 'Wed Sep 08 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 11 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 3,
        name: 'Ramona Kingsley',
        img: 'assets/img/customer/user-03.jpg',
        email: 'ramonakingsley@example.com',
        mobile: '608-712-2413',
        signup: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 07 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 4,
        name: 'Ricardo Lung',
        img: 'assets/img/customer/user-04.jpg',
        email: 'ricardolung@example.com',
        mobile: '714-564-6443',
        signup: 'Wed Sep 03 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 5,
        name: 'Annette Silva',
        img: 'assets/img/customer/user-05.jpg',
        email: 'annettesilva@example.com',
        mobile: '517-697-4628',
        signup: 'Wed Aug 31 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 04 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 6,
        name: 'Stephen Wilson',
        img: 'assets/img/customer/user-06.jpg',
        email: 'stephenwilson@example.com',
        mobile: '903-367-8263',
        signup: 'Wed Aug 19 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Aug 29 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 7,
        name: 'Ryan Rodriguez',
        img: 'assets/img/customer/user-07.jpg',
        email: 'ryanrodriguez@example.com',
        mobile: '	912-748-9361',
        signup: 'Wed Aug 15 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 13 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 8,
        name: 'Lucile Devera',
        img: 'assets/img/customer/user-08.jpg',
        email: 'luciledevera@example.com',
        mobile: '817-913-0462',
        signup: 'Wed Aug 06 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Aug 13 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 9,
        name: 'Roland Storey',
        img: 'assets/img/customer/user-09.jpg',
        email: 'rolandstorey@example.com',
        mobile: '201-929-0473',
        signup: 'Wed Jun 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },
      {
        id: 10,
        name: 'Lindsey Parmley',
        img: 'assets/img/customer/user-10.jpg',
        email: 'lindseyparmley@example.com',
        mobile: '740-485-5513',
        signup: 'Wed Sep 09 2020 09:41:48 GMT+0530 (India Standard Time)',
        last_login: 'Wed Sep 05 2020 09:41:48 GMT+0530 (India Standard Time)',        
        status: 'Pending',
      },    
    ];

    return {
      transactions: transactions,
      comments: comments,
      specialityList: specialityList,
      appointments: appointments,
      reviews: reviews,
      services: services,
      plans: plans,
      available_days: available_days,
      notifications: notifications,
      categories: categories,
      sub_categories: sub_categories,
      all_reports: all_reports,
      pending_reports: pending_reports,
      inprogress_reports: inprogress_reports,
      completed_reports: completed_reports,
      rejected_reports: rejected_reports,
      canceled_reports: canceled_reports,
      ratings: ratings,
      wallet_history: wallet_history,
      wallet_report: wallet_report,
      subscriptions: subscriptions,
      users: users,
    };
  }
}
